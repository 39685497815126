import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'dropdown-picker',
  templateUrl: './dropdown-picker.component.pug',
  styleUrls: ['./dropdown-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownPicker),
    multi: true
  }]
})
export class DropdownPicker implements ControlValueAccessor, OnInit {
  @Output() public onValueChanged: EventEmitter<any>;
  @Input() public options: Array<any> = [];
  @Input() public labelText: string;
  @Input() public value: any;
  @Input() public isInvalid: any;
  @Input() public selectedOption: any;
  @ViewChild('dropdownPicker', { static: false }) dropdownPicker: BsDropdownDirective;
  public opened = false;
  public filteredOptions: Array<any> = this.options;
  public isSerached = false;

  ngOnInit() {
    this.filteredOptions = this.options;
  }

  toggle(event: Event){
    event.stopPropagation(); // Prevent click event from propagating
    event.preventDefault(); // Prevent default behavior
    this.dropdownPicker.show();
  }

  public propagateChange = (_: any) => { };

  public onOptionSelected(option: any) {
    this.selectedOption = option;
    this.value = option.value != null ? option.value : option;
    this.propagateChange(this.value);
  }

  public writeValue(value: any) {
    if (value !== this.labelText && value !== this.value) {
      this.value = value;
      this.selectedOption = null;
      if (this.options) {
        for (let option of this.options) {
          if (option === value || (option.value != null && option.value === value)) {
            this.selectedOption = option;
            break;
          }
        }
      }
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public onSearch(event: any) {
    if (event.target.value && event.target.value != "") {
      this.filteredOptions = this.options.filter(function (option) {
        return ((option?.value || option).toString().toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) ? true : false;
      }).sort((a, b) => {
        const aValue = (a?.value || a).toString().toLowerCase();
        const bValue = (b?.value || b).toString().toLowerCase();
        const inputValue = event.target.value.toLowerCase();

        if (aValue.startsWith(inputValue) && bValue.startsWith(inputValue)) return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });
        else if (aValue.startsWith(inputValue)) return -1;
        else if (bValue.startsWith(inputValue)) return 1;

        return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });;
      });
      this.isSerached = true
    } else {
      this.filteredOptions = this.options
      this.isSerached = false;
    }
  }
}
