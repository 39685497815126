import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { merge } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import { BsModalService } from 'ngx-bootstrap/modal';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  AccountExpanded, Guest, QuestionTemplateId,
  RegistrationData, RegistrationExpanded, ScreeningExpanded
} from '../../../../../../common/interfaces';
import { Errors } from '../../../../../../common/lib/Errors';
import {
  AccountService, RegistrationService, ScreeningInvitationService,
  ScreeningService, SharedService
} from '../../../../services';
import { CanComponentDeactivate } from '../../../common/guards/pending-changes.guard';
import { CovidWaiverForm, IndustryForm, OnlineVenueForm, RegistrationForm } from '../../forms';
import { PriorityFullModal, SelectTimeModal, WaitlistModal } from '../../modals';
import { PfmHeader } from '../../pfm-header';

declare const ga: Function;

@Component({
  selector: 'confirm-attendance-page',
  styleUrls: ['./confirm-attendance-page.component.scss'],
  templateUrl: './confirm-attendance-page.component.pug'
})
export class ConfirmAttendancePage implements OnInit, CanComponentDeactivate {
  @ViewChild(PfmHeader, { static: false }) public pfmHeader: PfmHeader;
  @ViewChild(IndustryForm, { static: false }) public industryForm: IndustryForm;
  @ViewChild(CovidWaiverForm, { static: false }) public covidWaiver: CovidWaiverForm;
  @ViewChild(OnlineVenueForm, { static: false }) public onlineVenueForm: OnlineVenueForm;
  @ViewChild(RegistrationForm, { static: false }) public registrationForm: RegistrationForm;

  public isEditing: boolean;
  public isGuestValid: boolean;
  public isFamilyMode = true;
  public guests = new Array<Guest>();
  public kids: any[] = [];
  public guestCountOptions = new Array<number>();
  public showServerError: boolean;
  public loading: boolean = true;
  public waiting: boolean = false;
  public errorMsg: string;
  public showUpdateGuestError: boolean;
  public questionForm: FormGroup;
  public submitted: boolean;
  public confirming: boolean = false;
  public screeningAddress: string = '';
  public screeningLocation: string = '';
  public account: AccountExpanded;
  public registration: RegistrationExpanded;
  public screening: ScreeningExpanded;
  private accessTokenId: string;
  private notEncrypted: boolean = true;
  public priority: boolean = false;
  public priorityDeadline: Date;
  public hasRegistration: boolean = false;
  public onlineFocusGroup: boolean = false;
  public userTimezone: string;
  public logId: number;
  private isExternal: boolean = false;
  private inboundUrl: string = "";
  private recruit_id: string = "";
  private memberId: string = "";
  private urlToVerify: string = "";
  private hash: string = "";
  public showHighlightedError: boolean = false;
  public isOnlineVenue: boolean = false;
  public hasLinkedScreenings: boolean = false;
  public checkInTime: any;
  public hasDualScreening: boolean = false;

  // tslint:disable
  constructor(private route: ActivatedRoute, private router: Router,
    private titleService: Title, private accountService: AccountService,
    private screeningService: ScreeningService, private registrationService: RegistrationService,
    private sharedService: SharedService, private screeningInvitationService: ScreeningInvitationService,
    private fb: FormBuilder, private modalService: BsModalService) {
    // tslint:enable
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((navigation: any) => {
      ga('set', 'page', navigation.url);
      ga('send', 'pageview');
    });
  }
  // tslint:enable

  public ngOnInit() {
    this.loading = true;

    const currentUrl = this.route.snapshot.url.join("/");
    this.isExternal = currentUrl.includes("invite-external");
    this.inboundUrl = window.location.href;
    
    if (this.isExternal) {
      this.logId = this.route.snapshot.queryParams["logId"] || null;
      this.recruit_id = this.route.snapshot.queryParams["recruiterId"] || null;
      this.memberId = this.route.snapshot.queryParams["memberId"] || null;
      this.hash = this.route.snapshot.queryParams["hash"] || null;
      this.urlToVerify = this.router.url;
      

      if (this.logId) {
        this.updateRecruiterLog({ note : 'On Confirmation Page' })
      } else if (this.recruit_id && this.memberId) {
        const payload = {
          journey: "Attendance",
          urlToVerify: this.urlToVerify,
          inboundUrl: this.inboundUrl,
          recruiterId: this.recruit_id,
          memberId: this.memberId,
          hash: this.hash,
        };
        this.logRegistrationURL(payload);
      } else {
        this.setError();
        this.submitted = true;
        this.router.navigate(["registration-error"]);
      }
    }
    
    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    this.accessTokenId = accessTokenId;
    this.titleService.setTitle('Confirm Attendance');
    this.registration = this.sharedService.getScreeningRegistration();
    // tslint:disable-next-line
    this.isEditing = this.route.snapshot.data['isEditing'];
    this.sharedService.setIsEditing(this.route.snapshot.data['isEditing'])
    this.hasRegistration = this.route.snapshot.data['hasRegistration']
    this.userTimezone = moment.tz.guess();
    this.accountService.currentUser.subscribe(
      currentUser => {
        if (currentUser && (currentUser.emailVerified || this.isEditing)) {
          this.account = currentUser;
        } else {
          // Why are we setting null if we have an account being passed in?
          this.account = null;
        }
      }
    );
    if (this.hasRegistration || this.isEditing) {
      this.accountService.getSelf(accessTokenId).subscribe(
        currentUser => {
          this.account = currentUser ? currentUser : null;
        }
      );
      this.getRegistrationData();
    } else {
      this.getScreeningData();
    }
    
  }

  public updateRecruiterLog(payload:any) {
    this.accountService
      .updateRecruiterLog(this.logId, payload)
      .subscribe(
        (account) => {
          // this.showSuccessAlert = true;
          // Cookie.set("email", account.email, 0.375); // expire in 9 hours
          // this.router.navigate(["/registration/step-two"], {
          //   queryParams: { logId: this.logId },
          // });
        },
        (error) => {
          console.log('error', error);
          // this.showErrorAlert = true;
          // this.registrationError =
          //   error.status === 403 ? error.message : null;
          // form.reset();
        }
      );
  }

  public logRegistrationURL(payload: any) {
    this.accountService.logRegistrationURL(payload).subscribe(
      (res) => {
        if (res && typeof res === "string") {
          this.setError();
          this.submitted = true;
          this.router.navigate(["registration-error"]);
        } else if (res && res.id) {
          this.logId = res.id;
        }
      },
      (error) => {}
    );
  }

  public onLoginClicked() {
    this.pfmHeader.presentLogInModal();
  }

  public onNotMeClicked() {
    // logout
    this.pfmHeader.onLoginClicked();
  }

  public onFormSubmitted(form: any, questionValues: any, covidDeny: any, onlineReject: any, isInvalidForm?: boolean) {
    this.showHighlightedError = true;
    if (isInvalidForm) {
      return;
    }
    let registrationValues: RegistrationData = this.registrationForm.values();
    this.waiting = true;
    let accountData = registrationValues.Account;
    if (this.account) {
      accountData.id = this.account.id;
    }

    if (accountData.countryId !== 1 && accountData.phoneNumber) {
      // console.log(`Account Number 1: ${accountData.phoneNumber}`);
      accountData.phoneNumber.replace(/[`~!@#$%^&*_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      // console.log(`Account Number 2: ${accountData.phoneNumber}`);
    }
    let data = this.registration;
    data.Account = merge(accountData, questionValues.Account);
    data.Kids = questionValues.Kids
    // this.updateKidsAnswers(registrationValues.Kids, questionValues.Kids)
    // merge(questionValues.Kids, accountData.Kids);
    data.Answers = questionValues.Answers;
    data.Guests = questionValues.Guests;
    data.hiddenAgeQuestion = questionValues.hiddenAgeQuestion;
    data.notEncrypted = this.notEncrypted;
    data.covidDeny = covidDeny;
    data.onlineReject = onlineReject;
    if (!this.isEditing || this.registration.state === 'Pre-Approved') {
      this.submitRegistration(form, data, questionValues.isIndustryValid);
    } else {
      this.updateRegistration(data);
    }
    this.showHighlightedError = false;
  }

  public getIsSubmitted() {
    return this.submitted
  }

  private setError() {
    this.sharedService.setRegistrationError({
      errorTitle: 'Invalid URL',
      pageTitle: 'Invalid URL',
      message: 'Sorry, your URL seems to be not correct, Please contact support team.'
    });
  }

  // warn on browser refresh, close, etc
  @HostListener('window:beforeunload') public onWindowUnload() {
    if (!this.getIsSubmitted()) {
      return confirm('Unsaved changes will be lost. Are you sure you want to continue?');
    }
  }
  public canDeactivate() {
    return this.getIsSubmitted();
  }

  private submitRegistration(form: any, data: RegistrationExpanded, isIndustryValid?: boolean) {
    this.submitted = true;
    this.errorMsg = '';
    this.showServerError = false;
    if (this.isExternal) {
      data.isExternal = true;
    }
    if (!isIndustryValid) {
      data.Account.status = 'Industry Banned';
      if (!data.recruiterId) {
        data.recruiterId = this.sharedService.getRecruiterId();
      }
      this.accountService.industryBan(data).subscribe(res => {
        this.sharedService.setRegistrationError({
          errorTitle: 'Event Full',
          pageTitle: 'Event Full',
          message: 'Sorry, this event is closed due to full attendance.'
        });
        this.router.navigate(['registration-error']);
        return;
      }, err => {
        console.log(err);
      })
      // this.sharedService.setRegistrationError({
      //   errorTitle: 'Unable to register',
      //   pageTitle: 'Register Error',
      //   message: 'Sorry, your affiliation with the movie industry prevents us from allowing you to register.'
      // });
      // this.router.navigate(['registration-error']);
      // return;
    } else if (isIndustryValid) {
      // generate age group answer if hidden
      if (data.hiddenAgeQuestion && data.Account.birthDate) {
        let generatedAnswer = this.createAgeGroupQuestion(data.hiddenAgeQuestion, data.Account.birthDate);
        if (!data.Answers) { data.Answers = []; }
        if (generatedAnswer) { data.Answers.push(generatedAnswer); }
      } else {
        // verify age group with birthdate
        let ageQuestionAnswer = null;
        data.Answers.forEach((answer) => {
          if (answer.questionTemplateId === QuestionTemplateId.Age
            && answer.answerJSON && answer.answerJSON.values[0]) {
            ageQuestionAnswer = answer.answerJSON.values[0];
            return;
          }
        });

        let isAgeValid = this.isAgeGroupValid(ageQuestionAnswer, data.Account.birthDate);
        if (!isAgeValid) {
          this.showServerError = true;
          this.errorMsg = 'The age group and birthdate do not match.';
          this.waiting = false;
          form.reset();
          return;
        }

        let isAgeValidForRegistration = this.isAgeValidForRegistration(data.Account.birthDate);
        if (!isAgeValidForRegistration) {
          this.showServerError = true;
          this.errorMsg = 'You are not eligible for registration.';
          this.waiting = false;
          form.reset();
          return;
        }
      }

      if ((this.screening.isHighSecurity === true && this.screening.requireVerifiedEmail) && !data.Account.emailVerified) {
        this.showServerError = true;
        this.errorMsg = 'This is a high security event. Please log in or create an account and verify your email address before registering.';
        this.waiting = false;
        form.reset();
        return;
      }

      if (!data.recruiterId) {
        data.recruiterId = this.sharedService.getRecruiterId();
      }
      let reqData = data;
      reqData.Screening = null;
      this.confirming = true;
      data.priority = this.priority && this.hasRegistration; //PREVENT ANONYMOUS PRIORITY CONFIRMS
      data.priorityDeadline = this.priorityDeadline;
      if (data && data.Account.postalCode && data.Account.countryId === 241) {
        data.Account.postalCode = data.Account.postalCode.replace(/[\W_]/g, '');
      }
      this.screeningService.register(this.screening.id, data).subscribe(
        (registration: any) => {
          if (registration && registration.Account && registration.Account.id && registration.id) {
            this.updateRecruiterLog({ accountId: registration.Account.id, registrationId: registration.id, status: "Valid" })
          }
          this.confirming = false;
          this.sharedService.setScreeningRegistration(registration);
          if (registration.state === 'NoToWaiting' || registration.state === 'Full') {
            this.presentWaitlistModal(registration.id, this.screening);
          } else if (registration.state === 'Denied') {
            this.sharedService.setRegistrationError({
              errorTitle: 'Event Full',
              pageTitle: 'Event Full',
              message: 'Sorry, this event is closed due to full attendance.'
            });
            this.router.navigate(['registration-error']);
          } else if (registration.allowedScreeningIds && registration.state === 'Pre-Approved') {
            this.waiting = false;
            this.presentTimeSelectModal(registration, this.screening);
          } else if (registration.ndaId) {
            this.registrationService.sendNDA(registration.ndaId, registration.screeningId, this.accessTokenId).subscribe((resp) => {
              if (resp.ndaSent) {
                this.router.navigate(['/screenings', registration.screeningId, 'nda', registration.ndaId]);
              } else {
                // this.waiting = false;
                this.showServerError = true;
                this.errorMsg = 'There was an error sending your NDA. Please check your email address and try again.'
                this.waiting = false;
              }
            },
              (err) => {
                // this.waiting = false;
                console.log(err)
                this.showServerError = true;
                this.errorMsg = 'There was an error sending your NDA. Please check your email address and try again.'
                this.waiting = false;
              });
          } else if (registration.redirectUrl) {
            window.location.href = registration.redirectUrl
          } else if (registration && registration.state !== 'Denied') {
            this.router.navigate(['/screenings', this.screening.id, 'confirmation', registration.id]);
          }
        },
        error => {
          this.confirming = false;
          if (error && error.statusCode === Errors.codes.ERROR_MESSAGE_FORBIDDEN
            || error.statusCode === Errors.codes.ERROR_UNPROCESSABLE_ENTITY) {
            if (String(error.message) === String(Errors.messages.ERROR_EMAIL_EXISTS)) {
              this.errorMsg = 'An account with this email exists already. Please log in to continue.';
            } else if (String(error.message) === String(Errors.messages.ERROR_REGISTRATION_EXISTS)) {
              this.errorMsg = error.message;
            } else if (String(error.message) === String(Errors.messages.ERROR_PRIORITY_FULL)) {
              if (error.statusCode === Errors.codes.ERROR_MESSAGE_FORBIDDEN) { //HAS REGISTRATION
                this.presentPriorityFullModal(this.registration.id, this.screening);
              } else if (error.statusCode === Errors.codes.ERROR_UNPROCESSABLE_ENTITY) { //NO REGISTRATION
                this.presentPriorityFullModal(null, this.screening, { form, data, isIndustryValid });
              }
            } else {
              this.submitted = true;
              this.sharedService.setRegistrationError({
                errorTitle: 'Event Full',
                pageTitle: 'Event Full',
                message: 'Sorry, this event is closed due to full attendance.'
              });
              this.router.navigate(['registration-error']);
              return;
            }
          } else if (error && error.statusCode === Errors.codes.ERROR_NOT_FOUND) {
            this.router.navigate(['not-found']);
            return;
          }

          form.reset();
          this.submitted = false;
          this.showServerError = true;
          this.waiting = false;
        }
      );
    }
  }

  private updateKidsAnswers(currentKids: any[], kidsFormValues: any[]) {
    // TODO: return a modified version of the currentKids
    let updatedKids = structuredClone(currentKids)
    updatedKids = updatedKids.map((k, index) => {
      let updatedKid = structuredClone(k)
      updatedKid.Answers = updatedKid.Answers.map((ans: any, aIndex: number) => {
        // TODO: See if we have an updated value
        if (kidsFormValues[index] && kidsFormValues[index].Answers) {
          let foundUpdatedAnswer = kidsFormValues[index].Answers.find((kAnswer: any, kAnswerIndex: number) => {
            return kAnswer.questionId === ans.questionId && kAnswer.questionTemplateId === ans.questionTemplateId
          })
          if (foundUpdatedAnswer) {
            ans.answerJSON = foundUpdatedAnswer.answerJSON
          }
        }
        return ans
      })
      return updatedKid
    })
    return updatedKids
    // For each kid within the forms values
    // We need to find that matching kid, which we will need to use the index
    // For each kid, we need need to get the answers
    // Each sanswer has a questionId, questionTemplateId, answerJSON
  }

  // compare age group answer against birthdate
  private isAgeGroupValid(ageGroup, birthdate) {
    if (!ageGroup || !birthdate) { return true; }
    let min = null;
    let max = null;
    if (ageGroup.includes('+')) {
      min = +ageGroup.split('+')[0];
    } else {
      let parts = ageGroup.split('-');
      min = +parts[0];
      max = +parts[1];
    }
    let age = moment().diff(birthdate, 'years');
    if (age < min || (max && age > max)) {
      return false;
    }
    return true;
  }

  private isAgeValidForRegistration(birthdate) {
    if (!birthdate) { return true; }
    let min = 13;
    let age = moment().diff(birthdate, 'years');
    if (age < min) {
      return false;
    }
    return true;
  }

  private createAgeGroupQuestion(question, birthdate) {
    let age = moment().diff(birthdate, 'years');
    let ageGroups = [];
    question.configJSON.answers.forEach((item) => {
      let group = {
        name: item,
        min: null,
        max: null
      };
      if (item.includes('+')) {
        let n = item.split('+');
        group.min = Number(n[0]);
      } else {
        let n = item.split('-');
        group.min = Number(n[0]);
        group.max = Number(n[1]);
      }
      ageGroups.push(group);
    });
    let group;
    for (let i = 0; i < ageGroups.length; i++) {
      if (age >= ageGroups[i].min &&
        (!ageGroups[i].max || age <= ageGroups[i].max)) {
        group = ageGroups[i].name;
        break;
      }
    }
    let answer;
    if (group) {
      answer = {
        questionId: question.id,
        questionTemplateId: question.questionTemplateId,
        answerJSON: { label: question.label, values: [group] },
        accountId: this.account ? this.account.id : undefined
      };
    }
    return answer;
  }

  private updateRegistration(data: RegistrationExpanded) {
    let registrationData = this.registration;
    registrationData.Guests = data.Guests;
    this.confirming = true;
    this.registrationService.updateGuests(this.registration.id, registrationData, this.accessTokenId)
      .subscribe(
        registration => {
          let queryParams = {};
          if (this.accessTokenId) {
            // tslint:disable-next-line
            queryParams['access_token'] = this.accessTokenId;
          }
          this.confirming = false;
          this.sharedService.setScreeningRegistration(registration);
          //return this.screeningService.sendDigitalConfirmationEmail(data, true); //send confirmation email to guests only
          // this.screeningService.sendDigitalConfirmationEmail(data, true).subscribe(
          //   () => console.log('sent email'),
          //   err => console.log('email not sent: ', err)
          // );
          this.router.navigate(['/screenings', this.screening.id, 'confirmation', registration.id], { queryParams });
        },
        err => {
          this.confirming = false;
          this.showUpdateGuestError = true;
        }
      )
    // this.registrationService.updateGuests(this.registration.id, registrationData, this.accessTokenId).subscribe(
    //   registration => {
    //     let queryParams = {};
    //     if (this.accessTokenId) {
    //       // tslint:disable-next-line
    //       queryParams['access_token'] = this.accessTokenId;
    //     }
    //     this.confirming = false;
    //     this.screeningService.sendDigitalConfirmationEmail(registration, true); //send confirmation email to guests only
    //     this.sharedService.setScreeningRegistration(registration);
    //     this.router.navigate(['/screenings', this.screening.id, 'confirmation', registration.id], { queryParams });
    //   },
    //   err => {
    //     this.confirming = false;
    //     this.showUpdateGuestError = true;
    //   }
    // );
  }

  private getScreeningData() {
    // tslint:disable-next-line
    let invitationId = this.route.snapshot.params['invitationId'];
    if (invitationId) {
      this.registration.invitationId = invitationId;
      this.notEncrypted = false;

      this.screeningInvitationService.getById(invitationId, true).subscribe(
        invitation => {
          let code = invitation.Recruiter ? invitation.Recruiter.recruiterCode : 'N/A';
          this.sharedService.setRecruiterCode(code);
          // tslint:disable-next-line
          let screeningNumber = +this.route.snapshot.params['screeningNumber'];
          let screening = screeningNumber === 1 ? invitation.Screening1 : invitation.Screening2;
          this.registration.recruiterId = invitation.recruiterId;
          this.priority = invitation.forPriority ? invitation.forPriority : false;
          this.priorityDeadline = invitation.priorityDeadlineDate ? invitation.priorityDeadlineDate : null;

          this.isOnlineVenue = screening.Venue && screening.Venue.isOnline;
          this.hasLinkedScreenings = !!((screening.LinkedScreenings && screening.LinkedScreenings.length) && (screening.RuleSets && screening.RuleSets.length));
          this.hasDualScreening = !!invitation.screeningId2;
          if (screening.Venue && screening.Venue.name && !screening.Venue.isOnline && !['virtuworks screening room', 'virtu works screening room'].includes(screening.Venue.name.toLowerCase())) {
            this.userTimezone = screening.Venue && screening.Venue.timezone || this.userTimezone;
          }
          const checkInTimeArr:any[] = screening.EventType && screening.EventType.name !== 'Online Focus Group' && invitation.checkInTime1 ? invitation.checkInTime1.split(':') : [];
          this.checkInTime = checkInTimeArr.length > 1 ? moment.tz(screening.screeningStartDate, this.userTimezone).set('hour',checkInTimeArr[0]).set('minute', checkInTimeArr[1]) : screening.screeningStartDate;

          if (this.isOnlineVenue && screening.EventType && screening.EventType.name !== 'Online Focus Group') {
            this.checkInTime = moment.utc(screening.screeningStartDate).tz(this.userTimezone).subtract(1, 'hour')
          }

          if (invitation.title) {
            screening.title = invitation.title;
            if (invitation.isBlind) {
              screening.title = 'Movie Preview';
            }
          }
          this.setScreeningData(screening);
        },
        err => this.router.navigate(['not-found'])
      );
    } else {
      this.notEncrypted = true;
      if (!this.registration.Screening) {
        // tslint:disable-next-line
        let id = this.route.snapshot.params['id'];
        this.registration.screeningId = id;

        this.screeningService.getById(id).subscribe(
          screening => this.setScreeningData(screening),
          err => this.router.navigate(['not-found'])
        );
      } else {
        this.setScreeningData(this.registration.Screening);
      }
    }
  }

  private setScreeningData(screening: ScreeningExpanded) {
    this.registration.Screening = screening;
    this.sharedService.setScreeningRegistration(this.registration);
    this.screening = screening;
    if (this.screening.Venue && this.screening.Venue.name && !this.screening.Venue.isOnline && !['virtuworks screening room', 'virtu works screening room'].includes(this.screening.Venue.name.toLowerCase())) {
      this.userTimezone = this.screening.Venue && this.screening.Venue.timezone || this.userTimezone;
    }
    this.onlineFocusGroup = this.screening.Venue && this.screening.Venue.isOnline && this.screening.EventType.name === "Online Focus Group";
    this.titleService.setTitle('Confirm Attendance - ' + this.screening.title);
    this.screeningAddress = this.sharedService.getScreeningAddress(this.screening);
    this.screeningLocation = this.sharedService.getScreeningLocation(this.screening);
    this.loading = false;
  }

  private getRegistrationData() {
    if (Object.keys(this.registration).length < 2) {
      // tslint:disable-next-line
      let id = +this.route.snapshot.params['registrationId'];
      this.registrationService.getById(id, false, this.accessTokenId).subscribe(
        registration => {
          this.registration = registration;
          if (!registration || registration.state === 'Denied') {
            this.router.navigate(['not-found']);
          }
          this.setRegistrationData();
        },
        err => this.router.navigate(['not-found'])
      );
    } else {
      this.setRegistrationData();
    }
  }

  private setRegistrationData() {
    this.sharedService.setScreeningRegistration(this.registration);

    this.account = this.registration.Account;
    this.screening = this.registration.Screening;
    if (this.screening.Venue && this.screening.Venue.name && !this.screening.Venue.isOnline && !['virtuworks screening room', 'virtu works screening room'].includes(this.screening.Venue.name.toLowerCase())) {
      this.userTimezone = this.screening.Venue && this.screening.Venue.timezone || this.userTimezone
    }
    this.onlineFocusGroup = this.screening.Venue && this.screening.Venue.isOnline && this.screening.EventType.name === "Online Focus Group";
    if (!this.isEditing && this.registration.ScreeningInvitation) {
      this.priority = this.registration.ScreeningInvitation.forPriority
      if (this.priority) this.priorityDeadline = this.registration.ScreeningInvitation.priorityDeadlineDate
    }
    if (this.registration.Guests) {
      this.guests = this.registration.Guests.filter((guest) => {
        return guest.type === 'Guest';
      });
    }
    if (this.registration.Party && this.registration.Party.Kids) {
      this.kids = this.registration.Party.Kids
      // this.sharedService.setKids(this.registration.Party.Kids)
      // this.children = this.sharedService.getKids()
    }

    let screening = this.registration.Screening;
    this.isOnlineVenue = screening.Venue && screening.Venue.isOnline;
    this.hasLinkedScreenings = !!((screening.LinkedScreenings && screening.LinkedScreenings.length) && (screening.RuleSets && screening.RuleSets.length));
    if (screening.Venue && screening.Venue.name && !screening.Venue.isOnline && !["virtuworks screening room", "virtu works screening room"].includes(screening.Venue.name.toLowerCase())) {
      this.userTimezone = (screening.Venue && screening.Venue.timezone) || this.userTimezone;
    }
    const checkInTimeArr: any[] = screening.EventType && screening.EventType.name !== "Online Focus Group" && this.registration.ScreeningInvitation.checkInTime1 ? this.registration.ScreeningInvitation.checkInTime1.split(":") : [];
    this.checkInTime = checkInTimeArr.length > 1 ? moment.tz(screening.screeningStartDate, this.userTimezone).set("hour", checkInTimeArr[0]).set("minute", checkInTimeArr[1]) : screening.screeningStartDate;

    if (this.isOnlineVenue && screening.EventType && screening.EventType.name !== 'Online Focus Group') {
      this.checkInTime = moment.utc(screening.screeningStartDate).tz(this.userTimezone).subtract(1, 'hour')
    }
    
    let title = this.screening ? this.screening.title : '';
    this.titleService.setTitle('Confirm Attendance - ' + title);
    this.loading = false;
  }

  private presentWaitlistModal(registrationId: number, screening: any) {
    let screeningParams = {
      waitlistCount: screening.waitlistCount,
      waitlistMax: screening.waitlistMax
    }
    this.modalService.show(WaitlistModal, {
      initialState: {
        screening: screeningParams,
        registrationId
      }
    });
    // this.route.navigate(['']); //after modal close
  }

  private presentTimeSelectModal(registration: any, screening: any) {
    let registrationParams = {
      id: registration.id,
      screeningId: registration.screeningId
    }
    let timezone = screening.Venue.timezone || this.userTimezone;
    if (this.screening.Venue && this.screening.Venue.name && !this.screening.Venue.isOnline && !['virtuworks screening room', 'virtu works screening room'].includes(this.screening.Venue.name.toLowerCase())) {
      timezone = this.screening.Venue && this.screening.Venue.timezone || this.userTimezone
    }
    let screeningTimes = [screening, ...(screening.LinkedScreenings || [])].filter(s => (registration.allowedScreeningIds || []).includes(s.id)).map(s => ({ id: s.id, time: moment.tz(s.screeningStartDate, timezone).format('dddd, MMMM Do YYYY, h:mm:ss a') }))
    const modal = this.modalService.show(SelectTimeModal, {
      initialState: {
        registration: registrationParams,
        screeningTimes
      }
    });
    modal.content.onSubmit.subscribe((newRegistration: any) => {
      // this.router.navigate(newScreeningId ? ['/screenings', newScreeningId, 'nda', registration.ndaId] : ['']);
      if (newRegistration) {
        if (newRegistration.ndaId) {
          this.registrationService.sendNDA(newRegistration.ndaId, newRegistration.screeningId, this.accessTokenId).subscribe(
            (resp) => {
              if (resp.ndaSent) {
                this.router.navigate(['/screenings', newRegistration.screeningId, 'nda', newRegistration.ndaId]);
              } else {
                // this.waiting = false;
                this.showServerError = true;
                this.errorMsg = 'There was an error sending your NDA. Please check your email address and try again.'
              }
            },
            (err) => {
              // this.waiting = false;
              console.log(err)
              this.showServerError = true;
              this.errorMsg = 'There was an error sending your NDA. Please check your email address and try again.'
            }
          );
        } else {
          this.screeningService.sendDigitalConfirmationEmail(newRegistration, false).subscribe(
            () => this.router.navigate(['/screenings', newRegistration.screeningId, 'confirmation', newRegistration.id]),
            err => this.router.navigate(['/screenings', newRegistration.screeningId, 'confirmation', newRegistration.id])
          );
        }
      } else {
        this.router.navigate(['']);
      }
    }, (err) => {
      this.router.navigate(['']);
    });
  }

  private presentPriorityFullModal(registrationId: number, screening: any, submitParams?: any) {
    const modal = this.modalService.show(PriorityFullModal, {
      initialState: { registrationId }
    });
    modal.content.onSubmit.subscribe((result) => {
      if (result === 'keep') {
        this.router.navigate(['/screenings', screening.id, 'confirmation', registrationId]);
      } else if (result === 'new') {
        this.priority = false;
        this.submitRegistration(submitParams.form, submitParams.data, submitParams.isIndustryValid);
      } else if (result === 'cancel') {
        this.router.navigate(['']);
      }
    }, (err) => {
      this.router.navigate(['']);
    });
  }
}
