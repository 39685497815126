import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { CookieService } from 'ngx-cookie-service';

import moment from 'moment';
import { QuestionTemplateRegistrationFilter, RegistrationData } from '../../../../../../common/interfaces';
import { AccountService } from '../../../../services/account.service';
import { SharedService } from '../../../../services/shared.service';

import { distinctUntilChanged } from 'rxjs/operators';

declare const ga: Function;

@Component({
  selector: 'registration-page',
  styleUrls: ['./registration-page.component.scss'],
  templateUrl: './registration-page.component.pug',
})
export class RegistrationPage implements OnInit {
  public isParent: boolean;
  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;
  public registrationError: string;
  public email: string = "your email";
  public templateFilter: number;
  private recruiterId: number;
  public logId: number;
  private isExternal: boolean = false;
  private inboundUrl: string = "";
  private urlToVerify: string = "";
  private recruit_id: string = "";
  private memberId: string = "";
  private hash: string = "";

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private router: Router,
    private titleService: Title, private accountService: AccountService,
    private sharedService: SharedService, private cookieService: CookieService) {
    this.templateFilter = QuestionTemplateRegistrationFilter.Required;
    this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((navigation: any) => {
      ga('set', 'page', navigation.url);
      ga('send', 'pageview');
    });
  }

  public ngOnInit() {
    this.titleService.setTitle("Registration");
    const currentUrl = this.route.snapshot.url.join("/");
    this.isExternal = currentUrl.includes("register-external");
    this.inboundUrl = window.location.href;
    this.urlToVerify = this.router.url;
    if (this.isExternal) {
      this.recruit_id = this.route.snapshot.queryParams["recruiterId"] || null;
      this.memberId = this.route.snapshot.queryParams["memberId"] || null;
      this.hash = this.route.snapshot.queryParams["hash"] || null;

      if (this.recruit_id && this.memberId) {
        const payload = {
          journey: "Registration",
          urlToVerify: this.urlToVerify,
          inboundUrl: this.inboundUrl,
          recruiterId: this.recruit_id,
          memberId: this.memberId,
          hash: this.hash,
        };
        this.logRegistrationURL(payload);
      } else {
        this.setError();
        this.router.navigate(["registration-error"]);
      }
    }
  }

  private isAgeValidForRegistration(birthdate) {
    if (!birthdate) {
      return true;
    }
    let min = 13;
    let age = moment().diff(birthdate, "years");
    if (age < min) {
      return false;
    }
    return true;
  }

  public logRegistrationURL(payload: any) {
    this.accountService.logRegistrationURL(payload).subscribe(
      (res) => {
        if (res && typeof res === "string") {
          this.setError();
          this.router.navigate(["registration-error"]);
        } else if (res && res.id) {
          this.logId = res.id;

          if (res && res.recruiterId) {
            this.recruiterId = res.recruiterId;
          }
        } 
      },
      (error) => {}
    );
  }

  public onFormSubmitted(form: any, registrationValues: RegistrationData) {
    let account = registrationValues.Account;
    // We need to update the isParent value if its not an offered question and might not be needed
    // if (account && account.Kids && account.Kids.length) {
    //   account.isParent = true
    // }
    if (account && account.phoneNumber) {
      account.phoneNumber = account.phoneNumber.replace(/\D+/g, "");
    }

    if (!registrationValues.isIndustryValid) {
      account.status = "Industry Banned";
    }

    if (account && account.postalCode && account.countryId === 241) {
      account.postalCode = account.postalCode.replace(/[\W_]/g, "");
    }

    if (this.recruiterId) account.recruiterId = this.recruiterId;

    if (this.isExternal) {
      account.isExternal = true;
    }

    let isAgeValidForRegistration = this.isAgeValidForRegistration(
      account.birthDate
    );
    if (!isAgeValidForRegistration) {
      this.showErrorAlert = true;
      this.registrationError = "You are not eligible for registration.";
      form.reset();
      return;
    }

    this.accountService.register(account, registrationValues.Answers, registrationValues).subscribe((account) => {
      if (this.isExternal && this.logId) {
        this.accountService.updateRecruiterLog(this.logId, { accountId: account.id }).subscribe((account) => {
              this.showSuccessAlert = true;
              this.cookieService.set('email', account.email, 0.375); // expire in 9 hours
              this.router.navigate(["/registration/step-two"], {
                queryParams: { logId: this.logId },
              });
            }, (error) => {
              this.showErrorAlert = true;
              this.registrationError =
                error.status === 403 ? error.message : null;
              form.reset();
            }
          );
      } else {
        this.showSuccessAlert = true;
        this.cookieService.set('email', account.email, 0.375); // expire in 9 hours
        this.router.navigate(['/registration/step-two']);
      }
    }, (error) => {
      this.showErrorAlert = true;
      this.registrationError = error.status === 403 ? error.message : null;
      form.reset();
    });
  }

  private setError() {
    this.sharedService.setRegistrationError({
      errorTitle: 'Invalid URL',
      pageTitle: 'Invalid URL',
      message: 'Sorry, your URL seems to be not correct, Please contact support team.'
    });
  }
}
