import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'cookies-policy-page',
  styleUrls: ['./cookies-policy-page.component.scss'],
  templateUrl: './cookies-policy-page.component.pug'
})
export class CookiesPolicyPage implements OnInit {
  @ViewChild('accordion', { static: false }) private accordionEl: any;
  public gaIsOptOut: boolean;
  public cookies: any[];
  private click: boolean = false;

  constructor(private titleService: Title, private route: ActivatedRoute, private cookieService: CookieService) {

    this.cookies = [
      {
        type: 'Analytics',
        description: 'Analytics cookies are used for for tracking site analytics data, including user tracking and targeted advertising',
        isOpen: true,
        plugin: [
          {
            name: 'Google Analytics',
            sub: 'Third-Party',
            canOptOut: true,
            moreDetails: 'https://support.google.com/analytics/answer/6004245',
            cookies: [
              {
                name: '_ga, _gid, _gad',
                description: 'Used by Google Analytics',
                expiration: 'Session/1 Year'
              }
            ]
          }
        ]
      },
      {
        type: 'Security',
        description: 'Security cookies are used to help ensure our site remains protected from external threats',
        isOpen: false,
        plugin: [
          {
            name: 'Imperva Incapsula',
            sub: 'Third-Party',
            canOptOut: false,
            cookies: [
              {
                name: 'visid_incap_*, incap_ses_*, nlbi_*',
                description: 'Used by Imperva Incapsula (Web Access Firewall)',
                expiration: 'Session/1 Year'
              }
            ]
          }
        ]
      },
      {
        type: 'Essential',
        description: 'Essential cookies are necessary for proper site functionality and operation.',
        isOpen: false,
        plugin: [
          {
            name: 'Session',
            sub: 'First-Party',
            canOptOut: false,
            cookies: [
              {
                name: '_passenger_route',
                description: 'Sticky session cookie for Phusion Passenger',
                expiration: 'Session'
              },
              {
                name: 'accessToken',
                description: 'Used to store session access token',
                expiration: 'Session'
              }
            ]
          },
          {
            name: 'Privacy',
            sub: 'First-Party',
            canOptOut: false,
            cookies: [
              {
                name: 'gaOptOut',
                description: 'Used to store Google Analytics opt-out preference',
                expiration: '1 Year'
              }
            ]
          }
        ]
      }
    ]

  }

  public ngOnInit() {
    this.titleService.setTitle('Preview Free Movies - Cookies');
    let gaCookie = this.cookieService.get('gaOptOut');
    this.gaIsOptOut = [null, undefined, ''].indexOf(gaCookie) === -1 ? gaCookie === 'true' : false;
  }

  public gaToggle() {
    this.gaIsOptOut ? this.gaOptIn() : this.gaOptOut()
    this.gaIsOptOut = !this.gaIsOptOut
  }

  public gaOptOut() {
    this.cookieService.delete('_ga', '/', '.previewfreemovies.com');
    this.cookieService.delete('_gat', '/', '.previewfreemovies.com');
    this.cookieService.delete('_gid', '/', '.previewfreemovies.com');
    this.cookieService.set('gaOptOut', 'true', 365, '/')
    const gaRemoveTracker = window['gaRemoveTracker']
    gaRemoveTracker();
  }

  public gaOptIn() {
    this.cookieService.set('gaOptOut', 'false', 365, '/')
    const gaCreateTracker = window['gaCreateTracker']
    gaCreateTracker();
  }

  public e(i: any) {
    this.cookies.forEach((tab, x) => {
      tab.isOpen = i === x ? this.accordionEl.groups[i]._isOpen : false
    })
    this.cookies[i].isOpen = this.accordionEl.groups[i] ? this.accordionEl.groups[i]._isOpen : false;
    this.click = true;
  }

  public s(i: any) {
    if (!this.click) this.cookies[i].isOpen = true;
    this.click = false;
  }

  public d(i: any) {
    this.cookies[i].isOpen = false;
  }

  public t(i: any) {
    let isAny = this.cookies.reduce((prev, current, x) => {
      return {
        isOpen: prev.isOpen || current.isOpen
      }
    })
    return isAny.isOpen ? this.cookies[i].isOpen : (i === 0 ? true : false)
  }

}
